import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ChooseActionEditGoalDialogViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly view: Vue;

  readonly i18n_namespace = 'components.goals-dashboard.choose-action-edit-goal-dialog';

  readonly bucket_url = `${process.env.VUE_APP_S3_PUBLIC_URL}/investment/flagship/custom_investor_icons/`;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  edit_options = [
    {
      icon_src: 'new-icon-car.svg',
      icon_active_src: 'icon-car-white.svg',
      alt: this.translate('alts.custom').toString(),
      title: this.translate('edit_icon').toString(),
      description: this.translate('label_icon').toString(),
      option_name: 'edit_icon',
      is_active: false,
    },
    {
      icon_src: 'icon-edit.svg',
      icon_active_src: 'icon-edit-white.svg',
      alt: this.translate('alts.pencil').toString(),
      title: this.translate('edit_plan').toString(),
      description: this.translate('label_plan').toString(),
      option_name: 'edit_plan',
      is_active: false,
    },
  ];

  is_btn_continue_disabled = true;

  selected_option = 0;

  custom_goal_icon = '';

  custom_icon_file_id = '';

  updated_at = '';

  initialize = (custom_goal_icon: string, custom_icon_file_id: string, updated_at: string) => {
    [this.custom_goal_icon] = custom_goal_icon.split('.');
    this.custom_icon_file_id = custom_icon_file_id;
    this.updated_at = updated_at;
  }

  // eslint-disable-next-line consistent-return
  getIconPath(index: number) {
    if (this.custom_goal_icon) {
      const option = this.edit_options[index];
      if (index === 1) {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
        return require(`@/assets/icons/custom-goal-icons/${option.is_active ? option.icon_active_src : option.icon_src}`);
      }
      if (this.custom_goal_icon === 'icon-add' && this.custom_icon_file_id) {
        return `${this.bucket_url}${this.custom_icon_file_id}?${this.custom_goal_updated_at_time_stamp}`;
      }
      const icon_name = (!option.is_active) ? this.custom_goal_icon : `${this.custom_goal_icon}-white`;
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
      return require(`@/assets/icons/custom-goal-icons/${icon_name}.svg`);
    }
  }

  get custom_goal_updated_at_time_stamp() {
    return new Date(this.updated_at).getTime();
  }

  setActiveOption = (option_name: string) => {
    this.is_btn_continue_disabled = false;
    this.edit_options.forEach((deposit_option, index) => {
      const selected = option_name === deposit_option.option_name;
      this.edit_options[index].is_active = selected;
      if (selected) {
        this.selected_option = index;
      }
    });
  }

  showSelectedOptionDialog = () => {
    const selected_option = this.edit_options[this.selected_option];
    if (selected_option.option_name === 'edit_icon') this.showEditNameGoalModal();
    else this.showEditPlanGoalDialog();
  }

  showEditNameGoalModal = () => {
    this.view.$emit('showEditNameGoalModal');
  }

  showEditPlanGoalDialog = () => {
    this.view.$emit('showEditPlanGoalDialog');
  }
}
